/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-04-06",
    versionChannel: "nightly",
    buildDate: "2023-04-06T00:54:49.061Z",
    commitHash: "6315f3865f0556cee5a03a5db60311f97adcf845",
};
